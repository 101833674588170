/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style";
@import "~@ng-select/ng-select/themes/default.theme.css";
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
body {
  padding: 20px;
  // background-image: url(./assets/media/illustrations/sketchy-1/14.png);
  background: #f5f8fa;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  background-attachment: fixed;
}
.country-drop{
  .ng-select-container
  {
    background: transparent !important;
    border: none;
    border-color: transparent !important;
  }
  .ng-select.ng-select-opened>.ng-select-container
  {
    background: transparent !important;
    border: none;
    border-color: transparent !important;
  }
  .ng-select-container:hover
  {
    border: none;
    border-color: transparent !important;
    box-shadow: none !important;
  }
}
.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container
{
  border: none;
    border-color: transparent !important;
    box-shadow: none !important;
}

.form-control.form-control-solid
{
  border-color: #e1e1e1;
}
.btn.btn-primary {
  color: #FFFFFF;
  border-color: #0085f7;
  background-color: #0085f7;
}
.link-primary{
  // color: #FFFFFF;
  border-color: #0085f7;
  // background-color: #0085f7;
}
.btn.btn-danger {
  color: #FFFFFF;
  border-color: #dd4b39;
  background-color: #dd4b39;
}
.ng-select .ng-select-container {
  cursor: default;
  display: flex;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-height: 200px !important;
  overflow-y: auto !important;
}
.ngx-slider .ngx-slider-pointer.ngx-slider-active:after {
    background-color: white !important;
}
.ngx-slider .ngx-slider-tick {
   text-align: none !important;
   cursor: none !important;
   background: none !important;
}





.ng-touched .switch {
  background: #dadfe3;
}
.ng-untouched .switch  {
  background: #fff;
}
.ng-touched .switch.checked {
  background: #0085f7;
}


@media (min-width: 576px) {
  .ng-otp-input-wrapper{
    .otp-input:not(:last-child) {
      margin-right: 20px !important;
    }
    .otp-input {
      width: 50px !important;
      font-size: 1.15rem !important;
      height: 46px !important;
    }
  }
}
@media screen and (min-width: 280px)and (max-width: 380px) {
  .otp-input {
      width: 25px !important;
      font-size: 18px !important;
      height: 25px !important;
   }
  }
  @media screen and  (max-width: 279px) {
    .otp-input {
        width: 20px !important;
        font-size: 16px !important;
        height: 20px !important;
     }
    }
